var CommunitiesCtrl = (function () {
    var headers = document.querySelectorAll('.oma-community-tab-header');
    var sections = document.querySelectorAll('.community-section');

    document.querySelectorAll('.oma-community-tab-header a').forEach((elem) => {
        elem.addEventListener('click', function () {
            var containerId = elem.getAttribute("data-container-id");
            var containerType = elem.getAttribute("data-container-type");

            headers.forEach((elem) => {
                elem.classList.remove('oma-community-tab-active');
            });

            sections.forEach((elem) => {
                elem.classList.remove('body-active');
            });

            document.querySelector('.header-' + containerId).classList.add('oma-community-tab-active');
            document.querySelector('.section-' + containerId).classList.add('body-active');

            window.history.replaceState('', '', window.location.origin + window.location.pathname + "?active=" + containerId);
        });
    });
})();