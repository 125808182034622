$(function () {
    var currentIframe = $("#insurance-calculator-iframe");
    if (currentIframe.length) {
        if (screen.width > 760) {
            currentIframe[0].style.minWidth = iframeWidth + '%';
            currentIframe[0].style.height = iframeHeight + 'px';

        }
        else {
            currentIframe[0].style.minWidth = iframeMobileWidth + '%';
            currentIframe[0].style.height = iframeMobileHeight + 'px';
        }
    }

});