$(function () {
    $("#table-search-button").click(function () {
        SearchContact();
    });
    $('#table-searcher').keyup(function (event) {
        if (event.keyCode === 13) {
            SearchContact();
        }
    });
    $("#table-clear").click(function () {
        $.each($(".oma-table-block table tbody tr"), function () {
            document.getElementById("table-searcher").value = "";
            $(this).show();
        });
        SetLeftTableHeight();
    });

    function SearchContact() {
        $.each($(".oma-table-block table tbody tr"), function () {
            if (!new RegExp("\\b" + $('#table-searcher').val().toLowerCase() + "\\b").test($(this).text().toLocaleLowerCase()))
                $(this).hide();
            else
                $(this).show();
        });
        SetLeftTableHeight();
    }

    $("#ddlPrac,#ddlCity").change(function () {
        var practiceArea = $('#ddlPrac').find("option:selected").val();
        var city = $('#ddlCity').find("option:selected").val();
        if (practiceArea !== undefined && city !== undefined) {
            SearchData(practiceArea, city)
        }
    });

    function SearchData(practiceArea, city) {
        var cityCol = 0;
        var practiceAreaCol = 0;
        $('.jsOMASortableTable>thead>tr>th[scope="col"]').each(function (i, e) {
            let text = $(this).text().trim();
            if (text == "City") {
                cityCol = i;
            }
            if (text == "Focused Practice Area") {
                practiceAreaCol = i;
            }
        })
        if (practiceArea.toUpperCase() == 'ALL' && city.toUpperCase() == 'ALL') {
            $('.oma-table-block table tbody tr').show();
            SetLeftTableHeight();
        } else {
            $('.oma-table-block table tbody tr:has(td)').each(function () {
                var rowPracticeArea = $.trim($(this).find('td:eq(' + practiceAreaCol + ')').text());
                var rowCity = $.trim($(this).find('td:eq(' + cityCol + ')').text());
                if (practiceArea.toUpperCase() != 'ALL' && city.toUpperCase() != 'ALL') {
                    if (rowPracticeArea.toUpperCase() == practiceArea.toUpperCase() && rowCity == city) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                } else if ($(this).find('td:eq(0)').text() != '' || $(this).find('td:eq(0)').text() != '') {
                    if (practiceArea != 'all') {
                        if (rowPracticeArea.toUpperCase() == practiceArea.toUpperCase()) {
                            $(this).show();
                        } else {
                            $(this).hide();
                        }
                    }
                    if (city != 'all') {
                        if (rowCity == city) {
                            $(this).show();
                        }
                        else {
                            $(this).hide();
                        }
                    }
                }
            });
            SetLeftTableHeight();
        }

        $("#gp-gpp-clear").click(function () {
            $.each($(".oma-table-block table tbody tr"), function () {
                document.getElementById("ddlPrac").value = "all";
                document.getElementById("ddlCity").value = "all";
                $(this).show();
            });
            SetLeftTableHeight();
        });
    }
    $("#ddlConstituencyGroup").change(function () {
       
        var constituencyGroup = $('#ddlConstituencyGroup').find("option:selected").val();
        if (constituencyGroup !== undefined) {
            SearchConstituencyData(constituencyGroup)
        }
    });
    function SearchConstituencyData(constituencyGroup) {
        var constituencyGroupCol = 0;
        $('.jsOMASortableTable>thead>tr>th[scope="col"]').each(function (i, e) {
            let text = $(this).text().trim();
            if (text == "Constituency Group") {
                constituencyGroupCol = i;
            }
        })
        if (constituencyGroup.toUpperCase() == 'ALL') {
            $('.oma-table-block table tbody tr').show();
            SetLeftTableHeight();
        } else {
            $('.oma-table-block table tbody tr:has(td)').each(function () {
                var rowConstituencyGroup = $.trim($(this).find('td:eq(' + constituencyGroupCol + ')').text());
                if (constituencyGroup.toUpperCase() != 'ALL') {
                    if (rowConstituencyGroup.toUpperCase() == constituencyGroup.toUpperCase()) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                } else if ($(this).find('td:eq(0)').text() != '' || $(this).find('td:eq(0)').text() != '') {
                    if (constituencyGroup != 'all') {
                        if (rowConstituencyGroup.toUpperCase() == constituencyGroup.toUpperCase()) {
                            $(this).show();
                        } else {
                            $(this).hide();
                        }
                    }
                }
            });
            SetLeftTableHeight();
        }
    }
    function SetLeftTableHeight() {
        const tableHeight = $(".oma-table_scroll-right").height();
        $(".oma-table_scroll-left").height(tableHeight);
    }
});