$(document).ready(function () {
    var tabs = $('.oma-community-tab-header a').not('.oma-community-tab-header.staff a');
    var staffTabs = $('.oma-community-tab-header.staff a');

    tabs.on('click', function () {
        var tabId = $(this).attr('data-container-id');
      
        loadItemsForTab(tabId,1);
    });

    staffTabs.on('click', function () {
        var tabId = $(this).attr('data-container-id');

        loadItemsForTab(tabId, 1);
    });

    function getItems(tabId, containerId, page) {
      
        if (containerId) {
            var itemsContainer = $('#itemsContainer_' + containerId);
            var tabParams = {
                TabId: tabId,
                ContainerId: containerId,
                PageSize: itemsContainer.attr('data-page-size'),
                CurrentPage: page===undefined?itemsContainer.attr('data-current-page'):page,
            };

            $.ajax({
                url: '/communitiesApi/CommunityItemsApi/GetItemsByContainer',
                method: 'GET',
                dataType: 'json',
                contentType: 'application/json',
                data: tabParams
            }).done(function (data) {
                if (data) {
                    populateView(data, containerId);
                }
            }).fail(function () {
                if (window.console) {
                    console.log(arguments);
                }
            });
        }
    }

    function populateView(model, containerId) {
        var url ="/OMACommunityItems/GetItemsView"
        if (model) {
            $.ajax({
                url: url.toLowerCase(), //Required as all requests containing upper case letters are redirected to lowercase
                method: 'POST',
                dataType: 'html',
                contentType: 'application/json',
                data: JSON.stringify(model)
            }).done(function (data) {
                if (data) {
                  
                    var itemsContainer = $('#itemsContainer_' + containerId);
                    itemsContainer.html('');
                    itemsContainer.html(data);
                }
            }).fail(function () {
                if (window.console) {
                    console.log(arguments);
                }
            });
        }
    }

    // get the items for the active tab on page load
    var activeTab = $('.oma-community-tab-active a');

    if (activeTab.length > 0) {
        var tabId = $(activeTab).attr('data-container-id');

        loadItemsForTab(tabId);
    }
    function loadItemsForTab(tabId, page) {

        var tabContent = $('.section-' + tabId);
        var staffAccordions = tabContent.find('.oma-expanding-content__body.staff, .oma-expanding-content__body');
        
        for (var i = 0; i < staffAccordions.length; i++) {
            var accordion = $(staffAccordions[i]);

            var containerId = accordion.find('.items-container').attr('data-container-id');
            getItems(tabId, containerId, page);
        }
        if (staffAccordions.length == 0) {
            var containerId = tabContent.find('.items-container').attr('data-container-id');
            getItems(tabId, containerId, page);
        }
    }
});
