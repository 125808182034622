document.addEventListener('DOMContentLoaded', () => {
    const Constants = {
        TABLET_WIDTH: 768,
        BIG_CONTAINER_WIDTH: 600,
    };

    const ClassNames = {
        PRIORITY_CTA_BLOCK: '.oma-priority-cta-block.priority-default',
    };

    const priorityCtaBlocksArray = Array.from(document.querySelectorAll(ClassNames.PRIORITY_CTA_BLOCK));

    if (priorityCtaBlocksArray && priorityCtaBlocksArray.length > 0) {
        changePriorityBlocksFlexDirection();
        window.addEventListener('resize', () => changePriorityBlocksFlexDirection());
    }

    function changePriorityBlocksFlexDirection() {
        priorityCtaBlocksArray.forEach((item) => {
            var blockContainer = item.parentNode;
            if (blockContainer.offsetWidth >= Constants.BIG_CONTAINER_WIDTH) {
                item.firstElementChild.classList.add('flex-row');
            }
            else {
                item.firstElementChild.classList.remove('flex-row');
            }
        });
    }
});