(function ($) {
    var modalFuncs = {closeModal:closeModal, nextPage:nextPage, prevPage:prevPage, setPage:setPage};
    var ctas = $('.oma-apply-cta');
    for (var cta of ctas) {
        cta = $(cta);
        var useModal = cta.attr('data-use-modal') == 'True';
        setupCta(cta, useModal);
    }

    function setupCta(cta, useModal) {

        var elements = cta.find('.oma-apply-cta-button.modal-activator, .oma-apply-cta-link.modal-activator');
        if (useModal) {
            var modals = $('.oma-candidate-block-modal');
            modals.find('.close-button').click(closeModal);
            elements.click(handleClickWithModal);
            setupModalActions(cta);
            return;
        }
        elements.click(handleClickWithoutModal);
        
    }
    function handleClickWithoutModal() {
        var el = $(this);
        var href = el.attr('data-href');
        window.open(href, "_blank");
        return false;
    }
    function handleClickWithModal() {
        var el = $(this);
        var parent = el.closest('.oma-apply-cta');
        setModalPage(parent, 0);
        var modalContainer = parent.find('.oma-candidate-block-modal-container');
        modalContainer.addClass('active');
        return false;
    }
    function closeModal() {
        var modal = $(this).closest('.oma-candidate-block-modal-container');
        modal.removeClass('active');
    }
    function setModalPage(cta, pageNum) {
        var pages = cta.find('.modal-page-content > div');
        var page = undefined;
        for (var p of pages)
            if ($(p).attr('data-modal-page') == pageNum)
                page = $(p);

        if (!page) {
            console.error('Apply CTA block does not have any modal content');
            return;
        }
        var contentContainer = cta.find('.oma-candidate-block-modal');
        contentContainer = $(contentContainer);
        var innerContainer = $(contentContainer.find('.modal-content-container'));
        var clone = page.clone(true,true);
        innerContainer.empty().html(clone);
        contentContainer.attr('data-modal-current-page', pageNum);
        setPrevButtonVisibility(cta, pageNum > 0);
    }
    function setupModalActions(cta) {
        var actions = cta.find('.modal-page-action');
        for (var a of actions) {
            a = $(a);
            var act = a.attr('data-modal-action');
            a.click(modalFuncs[act]);
        }
    }

    function nextPage() {
        var el = $(this);
        var cta = el.closest('.oma-apply-cta');
        var contentContainer = cta.find('.oma-candidate-block-modal');
        contentContainer = $(contentContainer);
        var currentPage = contentContainer.attr('data-modal-current-page');
        currentPage = parseInt(currentPage, 10);
        currentPage++;
        setModalPage(cta, currentPage);
        return false;
    }
    function prevPage() {
        var el = $(this);
        var cta = el.closest('.oma-apply-cta');
        var contentContainer = cta.find('.oma-candidate-block-modal');
        contentContainer = $(contentContainer);
        var currentPage = contentContainer.attr('data-modal-current-page');
        currentPage = parseInt(currentPage, 10);
        currentPage--;
        if (currentPage < 0)
            currentPage = 0;
        setModalPage(cta, currentPage);
        return false;
    }
    function setPage() {
        var el = $(this);
        
        var cta = el.closest('.oma-apply-cta');
        var contentContainer = cta.find('.oma-candidate-block-modal');
        contentContainer = $(contentContainer);
        var pageNum = el.attr('data-modal-action-param');
        pageNum = parseInt(pageNum, 10);

        setModalPage(cta, pageNum);
        return false;
    }
    function setPrevButtonVisibility(cta, visible) {
        var prevButton = cta.find('.modal-prev-button');
        prevButton.css({ display: visible ? 'inline-block' : 'none' });
    }
})($);
