function breakpoint() {
    const breakpoints = {
        '(min-width: 1600px)': 'xxxl',
        '(min-width: 1440px) and (max-width: 1599.98px)': 'xxl',
        '(min-width: 1200px) and (max-width: 1439.98px)': 'xl',
        '(min-width: 992px) and (max-width: 1199.98px)': 'lg',
        '(min-width: 720px) and (max-width: 991.98px)': 'md',
        '(min-width: 360px) and (max-width: 719.98px)': 'sm',
        '(max-width: 360px)': 'xs',
    };

    for (let media in breakpoints) {
        if (window.matchMedia(media).matches) {
            return breakpoints[media];
        }
    }

    return null;
}

export default breakpoint;