document.addEventListener('DOMContentLoaded', () => {
    const Selectors = {
        DISCOUNT_CATEGORY_FORM: '#oma-discounts-category-form',
        DISCOUNT_CATEGORY_DROPDOWN: '#oma-discount-category-dropdown',
    };

    const discountCategoryForm = document.querySelector(Selectors.DISCOUNT_CATEGORY_FORM);
    const discountCategoryDropdown = document.querySelector(Selectors.DISCOUNT_CATEGORY_DROPDOWN);

    if (discountCategoryForm && discountCategoryDropdown) {
        discountCategoryDropdown.addEventListener('change', () => onDiscountCategoryDropdownChange());
    }

    function onDiscountCategoryDropdownChange() {
        discountCategoryForm.submit();
    }
});