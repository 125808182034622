window.toggleMarketplacePanelItem = function (categoryId) {
    $('#service_indicator_' + categoryId).toggleClass('flip');
    $('#service_' + categoryId).toggle()
}

window.doMarketplaceSearch = function () {
    event.preventDefault();
    var f = $('#marketplacesearchform');
    f.submit();
}
window.updateServiceCategory = function (serviceId) {
    var sg = $('#service_input_' + serviceId).attr('data-group');
    var anyChecked = $('input[name="service_input"][data-group="' + sg + '"]:checked').length;
    $('#service_container_' + serviceId).toggleClass('accordion-body-checkbox-container-selected');
    $('#service_group_' + sg).prop('checked', anyChecked);

}

$('#emarketplace-search').click((event) => {
    $('#emarketplace-no-result-text').addClass("searchTriggered");
});