$(function () {
    $('#search-page-input').keydown((event) => {
        if (event.keyCode == 13) {
            event.preventDefault();
        }
    });

    $('#search-page-input').keyup((event) => {
        if (event.keyCode == 13) {
            search($('#search-page-input').val());
        }
    });

    $('#search-page-button').click(() => {
        search($('#search-page-input').val());
    });

    function search(keyword) {
        const isAtMemberPage = window.location.pathname.split("/").includes("member");
        if (isAtMemberPage) {
            window.location.href = "/member/search?q=" + keyword;
        } else {
            window.location.href = "/search?q=" + keyword;
        }
    }
})