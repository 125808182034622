$(function () {
    const forms = $('.salesforce-case-form-link');
    if (!forms.length) return;
    var modal;
    var closeButton;
    var scrollY;

    window.Confirm = function (casetype, casesubtype) {
        var buttonId = modal.getElementsByClassName("confirm")[0].id;
        $('#' + buttonId + '').attr("disabled", "disabled");

        var spinner = modal.getElementsByClassName("spinner")[0];
        spinner.classList.add("submitted");
        let selectedCareerStageCookieValue = getCookie('SelectedCareerStage');
        let memberCareerStageCookieValue = getCookie('MemberCareerStage');
        var member = document.getElementsByClassName("member-detail")[0];
        let isStaff = member.dataset.isstaff;

        if (isStaff.toLowerCase() != "true") {
            let omaNumber = document.getElementById("omaNumber").innerText;
            let email = document.getElementById("memberEmail").innerText;
            if (omaNumber && omaNumber != "" && email && email != "" && casetype && casesubtype) {
                var bundleJson = {
                    "OMA_Number_Web__c": omaNumber,
                    "Type": casetype,
                    "OMA_Case_Sub_Type__c": casesubtype,
                    "Bundle_Career_Stage__c": selectedCareerStageCookieValue,
                    "Member_Career_Stage__c": memberCareerStageCookieValue,
                    "SuppliedEmail": email
                }
                submitBundleCase(bundleJson);
            }
            else {
                $(".invalid-case-input").show();
                $('#'+buttonId+'').removeAttr("disabled");                
            }
        }
        else {
            $(".oma-plus-userinfo").hide();
            $(".confirmation").show();           
        }
        resetCaptcha();
        spinner.classList.remove("submitted");
    }

    
    $(forms).click(function () {
        $(".confirmation").hide();
        $(".oma-plus-userinfo").show();
        if (modal) {
            modal = null;
        }
        modal = $(this)[0].nextElementSibling;

        openModal();
    });

    function openModal() {
        $(modal).addClass("active");
        disableScroll();
        closeButton = $(modal).find('.close-button');
        $(".case-submission-error").hide();
        $($(modal).find('.salesforce-case-form-modal')[0]).scrollTop(0);

        var buttonId = modal.getElementsByClassName("confirm")[0].id;
        $('#'+buttonId+'').attr("disabled", "disabled");
        
        $(window).click(function (event) {
            if (!!modal && !!closeButton[0] && (event.target == modal || event.target == closeButton[0])) {
                closeModal();
            }
        });

        $(document).keyup(function (e) {
            if (!!modal && modal.classList.contains("active") && e.keyCode == 27) { // Esc
                closeModal();
            }
        });
    }

    function disableScroll() {
        scrollY = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.top = `-${scrollY}px`;
    }
    
    function closeModal() {
            
        resetCaptcha();

        $(modal).removeClass("active");
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, scrollY); 
        modal = null;
        $(closeButton).off();
        closeButton = null;
    }

    window.addEventListener('scroll', () => {
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });

    function submitBundleCase(inputJson) {

        $.ajax({
            url: "/sfApi/Salesforce/PostBundleCase",
            type: "POST",
            data: JSON.stringify(inputJson),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (data) {;
                $(".oma-plus-userinfo").hide();
                $(".confirmation").show();
            },
            error: function (error) {
                {
                    console.log(JSON.stringify(error));
                    $(".case-submission-error").show();
                }
            }            
        });  
    };

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function resetCaptcha()
    {
        var captchaList = document.getElementsByClassName("g-recaptcha");
        for (var i = 0; i < captchaList.length; i++){
            grecaptcha.reset(i);
        }
    }

});