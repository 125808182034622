document.addEventListener('DOMContentLoaded', () => {
    const ClassNames = {
        SEARCH_NEWS_FORM: '.jsSearchNewsForm',
        CLEAR_ALL_BUTTON: '.jsClearAllButton'
    };

    const FormTypes = {
        TEXTBOX: 'TextBox',
        SELECT: 'Select',
        DATEPICKER: 'DatePicker'
    }

    const FormFieldsMapping = {
        'start': { Name: 'start', Type: FormTypes.DATEPICKER },
        'end': { Name: 'end', Type: FormTypes.DATEPICKER },
        'SearchType': { Name: 'SearchType', Type: FormTypes.SELECT },
        'SearchTopic': { Name: 'SearchTopic', Type: FormTypes.SELECT }
    }

    const searchNewsForms = document.querySelectorAll(ClassNames.SEARCH_NEWS_FORM);
    searchNewsForms.forEach((form) => {
        const clearAllButton = form.querySelector(ClassNames.CLEAR_ALL_BUTTON); 
        if (clearAllButton) {
            clearAllButton.addEventListener('click', function onClearAllClick() {
                var formElements = form.getElementsByClassName('form-control');

                for (var i = 0; i < formElements.length; i++) {
                    var elem = formElements[i];
                    if (FormFieldsMapping[elem.name]) {
                        var type = FormFieldsMapping[elem.name].Type;

                        switch (type) {
                            case FormTypes.DATEPICKER:
                                resetDatePicker(elem);
                                break;
                            case FormTypes.TEXTBOX:
                                resetTextbox(elem);
                                break;
                            case FormTypes.SELECT:
                                resetSelect(elem);
                                break;
                        }
                    }
                }

                window.location.href = window.location.href.substring(0, window.location.href.indexOf('?'));
            });
        }
    });

    function resetTextbox(textbox) {
        if (textbox) {
            textbox.value = '';
        }
    }

    function resetDatePicker(datePicker) {
        if (datePicker) {
            datePicker.valueAsDate = null;
        }
    }

    function resetSelect(select) {
        if (select) {
            select.selectedIndex = 0;
        }
    }
});