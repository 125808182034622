$(function () {
    let userName = getCookie('UserName');
    let remindSessionValue = 'remind';
    let remindSessionName = 'remind-vote';
    if (userName != '' || userName != null || userName != undefined)
    {
        remindSessionName = remindSessionName + userName;
        remindSessionValue = remindSessionValue + userName;
    }
    let voteRemind = getCookie(remindSessionName);
    if (voteRemind == "" || voteRemind != remindSessionValue)
    {
        $('#voting-notification').css('display', 'flex');
    }

    let actionRemind = getCookie('remind-action');
    if (actionRemind == "" || actionRemind != 'remind') {
        $('#banner-notification').css('display', 'flex');
    }

    let notificationHeight = $('.oma-notification-wrapper').height();
    $('.oma-notification').height(notificationHeight);

    $(window).resize(function () {
        let notificationHeight = $('.oma-notification-wrapper').height();
        $('.oma-notification').height(notificationHeight);
    });

    $('#vote-button').click(() => {
        $('#voting-notification').hide();
    });

    $('#action-button').click(() => {
        $('#banner-notification').hide();
    });

    $('#remind-button').click(() =>
    {
        let cookieLifetime = $('#remind-button').attr("data-cookieLifetime");
        let remindSessionValue = 'remind';
        let remindSessionName = 'remind-vote';
        if (userName != '' || userName != null || userName != undefined) {
            remindSessionName = remindSessionName + userName;
            remindSessionValue = remindSessionValue + userName;
        }
        setCookie(remindSessionName, remindSessionValue, (cookieLifetime/24) );
        $('#voting-notification').css('display', 'none');
    });

    $('#snooze-button').click(() => {
        let cookieLifetime = $('#snooze-button').attr("data-cookieLifetime");
        setCookie('remind-action', 'remind', cookieLifetime);
        $('#banner-notification').css('display', 'none');
    });


    let cookieConsent = getCookie('cookieconsent_status');
    if (cookieConsent == "" || cookieConsent != 'dismiss') {
        $('#oma-cookie-notification').css('display', 'flex');
    }

    $('#cookie-accept-button').click(() => {
        setCookie('cookieconsent_status', 'dismiss', 365);
        $('#oma-cookie-notification').hide();
    });

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    if (screen.width <= 920) {
        var bannerList = $('.oma-notification');
        var menu = $('#menu');
        if (bannerList.length > 0) {
            var publicMsgBanner = $('.member-public-content-message');
            if (menu.length > 0) {
                if (publicMsgBanner.length > 0) {
                    menu.css({
                        position: "relative",
                        top: "-50px",
                        float: "left"
                    });
                }
                else {
                    menu.css({
                        position: "relative",
                        top: "-55px",
                        float: "left"
                    });
                }
                
            }
            $('#Header').css('height', 'auto');
            var lastBanner = bannerList[bannerList.length-1];
            lastBanner.style.marginBottom = '10px';

        }        
    }
});

$(window).scroll(function () {
    var scrollTop = $(window).scrollTop();

    var publicMsg = $('.member-public-content-message');
    if (publicMsg.length > 0)
    {
        if (scrollTop >= publicMsg[0].clientHeight) {
            var publicBannerHeight = (0 - publicMsg[0].clientHeight) + "px";
            $('.oma-notification').first().css('margin-top', publicBannerHeight);
        }
        else {
            $('.oma-notification').first().css('margin-top', '');
        }

    }
    else
    {
        $('.oma-notification').first().css('margin-top', '');
    }
    
});