document.addEventListener('DOMContentLoaded', () => {
    const Selectors = {
        EXPAND_BUTTON: '.nav-list .main-item-expand-button',
        SUB_ITEMS: '.sub-items',
    };

    var expandButtons = Array.from(document.querySelectorAll(Selectors.EXPAND_BUTTON));

    if (expandButtons && expandButtons.length > 0) {
        for (i = 0; i < expandButtons.length; i++) {
            expandButtons[i].addEventListener('click', onExpandButtonClick);
        }
    }

    function onExpandButtonClick() {
        var mainItem = this.parentNode.parentNode;
        var subItems = mainItem.querySelector(Selectors.SUB_ITEMS);
        if (subItems) {
            mainItem.classList.toggle('expanded');
        }
    }
});