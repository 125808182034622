$(function () {

    const tiles = $('.oma-profile-tile');
    if (!tiles.length) return;

    var body = document.querySelector("body");
    var modal, closeButton;

    function disableScroll() {
        document.body.scrollTop = 0;
    }

    $(tiles).click(function () {
        modal = $(this)[0].children[2];
        closeButton = $(this).find('.close-button');
        $(modal).addClass("active");

        if (!(event.target == modal || event.target == closeButton[0])) {
            body.addEventListener('scroll', disableScroll);
        }
    });

    $(window).click(function (event) {
        if (!!modal && !!closeButton[0] && (event.target == modal || event.target == closeButton[0])) {
            closeModal();
        }
    });

    $(document).keyup(function (e) {
        if (!!modal && modal.classList.contains("active") && e.keyCode == 27) { // Esc
            closeModal();
        }
    });

    function closeModal() {
        $(modal).removeClass("active");
        body.removeEventListener('scroll', disableScroll);
    }
});