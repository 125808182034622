Array.prototype.contains = function(v) {
    for(var i = 0; i < this.length; i++) {
        if(this[i] === v) return true;
    }
    return false;
};
Array.prototype.unique = function() {
    var arr = [];
    for(var i = 0; i < this.length; i++) {
        if(!arr.contains(this[i])) {
            arr.push(this[i]);
        }
    }
    return arr;
}
if (!Object.entries) {
    Object.entries = function (obj) {
        var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };
}

$(function () {

    window.helpMeChoose = {};
    var careerChoice = {};

    var careerChoiceOnScreen = '';

    var solutionChoice = {};
    var concernChoice = {};
    var productPages = [];
    let currentStep = 1;
    let visibleStep = '';


    $('.step').css('visibility', 'hidden');
    $('.step1').css('visibility', 'visible');
    $('button[name="back"]').css('display', 'none');
    $('button[name="getResults"]').css('display', 'none');
    //class selector for the current step
    visibleStep = '.step' + currentStep;


    $('button[name="retake-quiz"]').click(function () {
        UI_update(-3);
        $('.step-counter').show()
        $('.choice-navigator').show();
    })
    $('button[name="next"]').attr('disabled', 'disabled');

    $(".step1 ul li a").click(function (e) {

        e.preventDefault();

        $(".step1 ul li a").removeClass('active');
        $(this).addClass("active");

        careerChoice = $(this).attr('choice');
        careerChoiceOnScreen = $(this).attr('aria-label');
        $('button[name="next"]').removeAttr('disabled');
        window.helpMeChoose.careerStage = {
            id: parseInt(careerChoice, 10),
            title: careerChoiceOnScreen
        };
        return false;
    });





    function loadItems(stepNum) {

        if (stepNum === 1) {

            getSolutions(careerChoice);
        };
        if (stepNum === 2) {
            getConcerns();
        }
        

    }

    $('button[name="next"]').click(function (e) {
        loadItems(currentStep);
        $('button[name="next"]').attr('disabled', 'disabled');
        UI_update(1);
    });
    $('button[name="back"]').click(function (e) {
        $('button[name="next"]').removeAttr('disabled');
        UI_update(-1);

    });
    //$('button[name="getResults"]').click(function () {
    //    /*
    //     * Perhaps We can call get products once for each checkbox and concatenate the results into a single object inside the JS.
    //     * 
    //     * 
    //     * 
    //    */
    //    getProducts();
    //    UI_update(1);
    //});
    function UI_update(n) {
        currentStep = currentStep + n;
        
        $('.step-counter').empty().append("Step " + currentStep + " of 3");
        currentMargin = ((currentStep - 1) * 100);
        currentMargin = '-' + currentMargin + "%";
        $('.choiceResults').css('margin-left', currentMargin);
        visibleStep = '.step' + currentStep;
        $('.step').css('visibility', 'hidden');
        $('.step').each(function () {
            if (!$(this).hasClass(visibleStep.substring(1))) {
                $(this).css('max-height', 0);
            } else {
                $(this).css('max-height', 'unset');
            }
        })
        let b_next = $('button[name="next"]');
        let b_back = $('button[name="back"]');
        let b_results = $('button[name="getResults"]')

        if (currentStep <= 1) {
            currentStep = 1;

            b_back.css('display', 'none');
            b_next.css('display', 'inline-block');
            b_results.css('display', 'none');
        } else if (currentStep == 3) {
            b_back.css('display', 'initial');
            b_next.css('display', 'none');
            b_results.css('display', 'inline-block');
        } else if (currentStep <= 3) {
            b_next.css('display', 'inline-block');
            b_back.css('display', 'initial');
            b_results.css('display', 'none');
            $('.step-counter').show();
            $('.choice-navigator').show();
        }
        if (currentStep == 4) {
            $('.step-counter').hide()
            $('.choice-navigator').hide();
        }
        window.scroll(0, 0);
        $(visibleStep).css('visibility', 'visible');

    }
    function getSolutions() {

        //the value for career stage is provided by the choice attribute of the selected item in Step 1
        $.ajax({
            url: "/Api/Category?careerStageID=" + careerChoice,
            type: "GET",
            dataType: "json",
            headers: { "accept": "application/json;odata=verbose" },
            success: function (data) {
                solutionTypes = [];
                $.each(data.results, function (index, item) {
                    solutionTypes.push(item);
                });

                step2Build();
            },
            error: function (error) {

                    //this try function bypasses the errors from local licensing issues.
                    try {
                        var res = error.responseText;
                        res = JSON.parse(res.substring(res.indexOf('{')));

                        solutionTypes = [];
                        res.results.forEach(item => solutionTypes.push(item))

                        step2Build();
                    }
                    catch {
                        if (window.console) {
                            console.log(JSON.stringify(error));
                        }
                    };
            }
        });
    };

    function step2Build() {
        /* $(".step4").hide().empty();
         $(".step3").hide().empty();
         $(".step3Title").hide();
         $(".step4Title").hide();
         $(".step2Title").fadeIn(200);*/

        $(".step2 .product-types").hide().empty().append(function () {

            var stepHtml = "";
            $.each(solutionTypes, function (index, item) {
                var desc = '';
                stepHtml += "<li><a href='/Pages/Help-Me-Choose-Test.aspx' choice-index='" + index + "' choice='" + item.name + "' aria-label='" + item.name + "'>";

                if (item.imageUrl != '') {
                    stepHtml += "<img src='" + item.imageUrl + "' alt='" + item.imageAlt + "' />"
                }
                if (item.description)
                    desc = item.description
                else
                    desc = item.name;
                if (item.description != '') {
                    stepHtml += "<div class='cat-description'>" + desc + "</div></a></li>";
                }
            });

            return stepHtml;
        }).after(function () {
            $(".step2 ul li a").click(function (e) {
                e.preventDefault();
                $('.step2 ul li a').removeClass('active');
                $(this).addClass('active');
                var choiceIndex = $(this).attr("choice-index");
                careerChoiceClass = $(this).attr("choice");
                solutionChoice = solutionTypes[choiceIndex];
                
                $('button[name="next"]').removeAttr('disabled');

                window.helpMeChoose.solutionType = {
                    id: solutionChoice.solutionTypeID,
                    title: solutionChoice.name
                };
                return false;

            });
        }).fadeIn(200);
    };

    function getConcerns() {
        $.ajax({
            url: "/helpMeChooseApi/Category/GetConcerns",
            type: "POST",
            data: JSON.stringify(solutionChoice),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (data) {
                customerConcerns = [];
                $.each(data.concerns, function (index, item) {
                    customerConcerns.push(item);
                });

                step3Build();
            },
            error: function (error) {
                //this try function bypasses the errors from local licensing issues.
                try {
                    var res = error.responseText;
                    res = JSON.parse(res.substring(res.indexOf('{')));

                    customerConcerns = [];
                    res.concerns.forEach(item => customerConcerns.push(item))

                    step3Build();
                } catch {
                    if (window.console) {
                        console.log(JSON.stringify(error));
                    }
                }
            }
        });
    };

    function step3Build() {
        /*   $(".step4").hide().empty();
           $(".step4Title").hide();
           $(".step3Title").fadeIn(200);*/
        $(".step3 .concerns").hide().empty().append(function () {
            var stepHtml = "<ul>";
            $.each(customerConcerns, function (index, item) {

                stepHtml += "<li><input type='checkbox' id='" + index + "' name='" + item.name + "' choice-index='" + index + "' /><label for='" + index + "'>" + item.name + "</label></li>";
            });

            stepHtml += "</ul>";
            return stepHtml;
        }).after(function () {
            $(".step3 ul li input").change(function (e) {
                let checked = $(".step3 ul li input[type='checkbox']:checked");

                if (checked.length > 0)
                    $('button[name="getResults"]').removeAttr('disabled');
                else
                    $('button[name="getResults"]').attr('disabled','disabled');

                if (checked.length >= 3) {
                    $(".step3 ul li input[type='checkbox']:not(:checked)").attr('disabled', 'disabled');
                } else { 
                    $(".step3 ul li input[type='checkbox']").removeAttr('disabled');
                }

                concernChoice = {};
                $(".step3 ul li input[type='checkbox']:checked").each(function (index) {
                   
                    var choiceIndex = $(this).attr("choice-index");
                    concernChoice[index] = customerConcerns[choiceIndex];
                })
                window.helpMeChoose.customerConcerns = Object.keys(concernChoice).map((key) => { return { id: concernChoice[key].customerConcernID, title: concernChoice[key].name }; });
                return false;
            });
        }).fadeIn(200);
    };

    function getProducts() {
        
        productPages = [];
        for ([key, prop] of Object.entries(concernChoice)) {
            $.ajax({
                url: "/helpMeChooseApi/Category/GetOmaiProducts",
                type: "POST",
                data: JSON.stringify(prop),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) {
                    
                    $.each(data.products, function (index, item) {
                        productPages.push(item);
                    });
                    console.log(data);
                    step4Build();
                },
                error: function (error) {
                    if (window.console) {
                        console.log(JSON.stringify(error));
                    }
                }
            });
        }
    };

    function step4Build() {
        $(".step4 .product-listing").hide().empty().append(function () {
            $('.career-choice').empty().append(careerChoiceOnScreen);
            $('.solution-type').empty().append(solutionChoice.Name);
            $('.concerns').empty().append(function () {
                var concerns = '';
                for ([key,prop] of Object.entries(concernChoice)) {
                    concerns += `${prop.Name + ', '}`;
                }
                //remove ', ' from the end of the string
                return concerns.substring(0, concerns.length - 2);
            });
            var stepHtml = "<h2>These are the OMA products recommended for you.</h2><ul class='product-list'>";
            $.each(productPages, function (index, item) {
                stepHtml += "<li class='product-list-item'><a class='title' href='" + item.Url + "'><img src='" + item.thumbnail + "' class='product-thumbnail' alt='" + item.name + "' /><h3>"+ item.name+"<p>" + $.trim(item.caption) + "</p></a></li>";
            });
            
                    
                
            stepHtml += "</ul>";
            return stepHtml;
        }).fadeIn(200);
    }
});
