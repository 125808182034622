//const { prefetch } = require("webpack");

$(document).ready(function () {
    $("#btnPostalCode").click(function () {
        $("#PCodeResult").hide();
        document.getElementById("error").innerHTML = "";
        //validation
        if ($("#postalCode").val() == "" ||
            $("#postalCode").val() == null) {
            document.getElementById("error").innerHTML = "Postal Code must be entered.";
            return false;
        }
        //Validate Postal Codes
        if ($("#postalCode").val() != ""
            && $("#postalCode").val() != null
            && !$("#postalCode").val().match(/[A-z][\d][A-z] ?[\d][A-z][\d]/)) {
            document.getElementById("error").innerHTML = "Invalid Canadian Postal Code";
            return false;
        }

        //Format Postal Code string
        var postalCode;
        postalCode = $("#postalCode").val();
        postalCode = String(postalCode);
        postalCode = postalCode.replace(/\s/g, '');
        postalCode = postalCode.slice(0, 3) + ' ' + postalCode.slice(3);

        //postalcode API call
        $.ajax({
            url: "/rioApi/Rio/GetRioResult?postalcode=" + postalCode,
            type: "GET",
            success: function (Presult) {
                var data = JSON.parse(Presult) //$.parseJSON(Presult)
                document.getElementById("RIOCityName").innerHTML = data.csdName;
                document.getElementById("RIOScore").innerHTML = data.ruralityIndex;
                $("#PCodeResult").show();
            },
            error: function (error) {
                document.getElementById("error").innerHTML = error.responseText;
                $("#PCodeResult").hide();
            }
        });
        return false;
    });
});
