import breakpoint from "../Breakpoint";

function isMobileView() {
    return breakpoint() === 'xs' || breakpoint() === 'sm' || breakpoint() === 'md' || breakpoint() === 'lg';
}

document.addEventListener('DOMContentLoaded', () => {
    const Selectors = {
        VIDEO_IFRAME: 'jsVideoCollectionIframe',
        VIDEO_TITLE: '.jsVideoCollectionTitle',
        VIDEO_SUBTITLE: '.jsVideoCollectionSubTitle',
        VIDEO_TEXT: '.jsVideoCollectionText',
        VIDEO_ITEM: '.jsVideoCollectionItem',
        VIDEO_MODAL: '.jsVideoItemModal',
        VIDEO_MODAL_TITLE: '.jsVideoItemModalTitle', 
        VIDEO_MODAL_SUBTITLE: '.jsVideoItemModalSubtitle', 
        VIDEO_MODAL_SUMMARY: '.jsVideoItemModalSummary', 
        VIDEO_MODAL_IFRAME: '.jsVideoItemModalIframe',
    };

    const DataAttributes = {
        DATA_VIDEO_LINK: 'data-video-link',
        DATA_TITLE: 'data-title',
        DATA_SUBTITLE: 'data-subtitle',
        DATA_TEXT: 'data-text-html',
    };

    const videoIframe = document.getElementById(Selectors.VIDEO_IFRAME);
    const videoTitleDiv = document.querySelector(Selectors.VIDEO_TITLE);
    const videoSubtitleDiv = document.querySelector(Selectors.VIDEO_SUBTITLE);
    const videoTextDiv = document.querySelector(Selectors.VIDEO_TEXT);

    const videoItems = Array.from(document.querySelectorAll(Selectors.VIDEO_ITEM));

    if (isMobileView()) {
        const modalDiv = document.createElement("div");
        modalDiv.classList.add("modal", "oma-video-block-modal", "jsVideoItemModal");
        modalDiv.id = "video-details-modal";
        modalDiv.setAttribute("tabindex", "-1");
        modalDiv.setAttribute("role", "dialog");
        modalDiv.innerHTML +=
            `<div class="modal-dialog" role="document" >
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="video-container">
                            <iframe class="jsVideoItemModalIframe" allow="autoplay;"
                                allowfullscreen="" frameborder="0" height="300px"
                                id="videoplayer" width="100%" src="">
                            </iframe>
                         </div>
                         <div class="story">
                            <div class="title jsVideoItemModalTitle" tabindex="0"></div>
                            <div class="subtitle jsVideoItemModalSubtitle" tabindex="0"></div>
                            <div class="summary jsVideoItemModalSummary"></div>
                         </div>
                    </div>
                </div>
            </div>`;
        document.body.appendChild(modalDiv);
    }

    if (videoItems && videoItems.length > 0) {
        for (let i = 0; i < videoItems.length; i++) {
            videoItems[i].addEventListener('click', onVideoItemClick);
        }
    }

    function onVideoItemClick() {
        const videoLink = this.getAttribute(DataAttributes.DATA_VIDEO_LINK);
        const newTitle = this.getAttribute(DataAttributes.DATA_TITLE);
        const newSubTitle = this.getAttribute(DataAttributes.DATA_SUBTITLE);
        const newText = this.getAttribute(DataAttributes.DATA_TEXT);

        if (!isMobileView()) {
            videoIframe.src = videoLink;
            videoTitleDiv.innerHTML = newTitle;
            videoSubtitleDiv.innerHTML = newSubTitle;
            videoTextDiv.innerHTML = newText;
        } else {
            const videoModal = document.querySelector(Selectors.VIDEO_MODAL);
            if (videoModal) {
                let videoModalIframe = videoModal.querySelector(Selectors.VIDEO_MODAL_IFRAME);
                videoModalIframe.src = videoLink;

                let videoModalTitle = videoModal.querySelector(Selectors.VIDEO_MODAL_TITLE);
                videoModalTitle.innerHTML = newTitle;

                let videoModalSubtitle = videoModal.querySelector(Selectors.VIDEO_MODAL_SUBTITLE);
                videoModalSubtitle.innerHTML = newSubTitle;

                let videoModalSummary = videoModal.querySelector(Selectors.VIDEO_MODAL_SUMMARY);
                videoModalSummary.innerHTML = newText;
            }
        }
    }
});