function resizeGridItem(item) {
    var grid = document.getElementsByClassName("oma-masonry-container-block")[0];
    var rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    var rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    var rowSpan = Math.ceil((item.querySelector('.oma-publication-article-tile-block-content').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
    item.style.gridRowEnd = "span " + rowSpan;
}

function resizeAllGridItems() {
    if (document.getElementsByClassName("oma-masonry-container-block").length) {
        var allItems = document.getElementsByClassName("oma-masonry-container-block")[0].children;
        for (var x = 0; x < allItems.length; x++) {
            resizeGridItem(allItems[x]);
        }
    }
}

function resizeInstance(instance) {
    var item = instance.elements[0];
    resizeGridItem(item);
}


window.addEventListener("resize", resizeAllGridItems);

window.addEventListener("load", event => {
    var images = document.querySelectorAll('.oma-masonry-container-block img');
    images.forEach(function (i) {
        if (i.complete && i.naturalHeight !== 0) {
            resizeAllGridItems()
        }
    });
});
