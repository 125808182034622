window.hideAllSelector =function ()
{
    $('.topicSelector').hide(); $('.locationSelector').hide();
}

window.toggleTopic = function() {
    hideAllSelector();
    event.stopPropagation(); $('.topicSelector').toggle();
}

window.toggleLocation = function() {
    hideAllSelector();

    event.stopPropagation(); $('.locationSelector').toggle();
}