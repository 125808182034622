if ($("#pcfaForm").length > 0) {

    function timestamp() {
        var response = document.getElementById("g-recaptcha-response");
        if (response == null || response.value.trim() == "") {
            var elems = JSON.parse(
                document.getElementsByName("captcha_settings")[0].value
            );
            elems["ts"] = JSON.stringify(new Date().getTime());
            document.getElementsByName(
                "captcha_settings"
            )[0].value = JSON.stringify(elems);
        }
    }
    function captcha_callback(key) {
        if (key !== "") {
            $("#btnPcfaSubmit").removeAttr("disabled");
        }
    }
    function captcha_expired() {
        $("#btnPcfaSubmit").attr("disabled", "disabled");
    }

    function validCheck(item) {
        
        if (item.is(":invalid")) {

            formValid = false;

            item.parent().find("label").css('color', '#f30000');
            let error = item.parent().find(".error");

            if (error.text() != "") {
                error.show();
            } else {
                error.text("This field is required");
                error.show();
            }
        } else {
            item.parent().find("label").css('color', 'initial');
            item.parent().find(".error").hide();
        }
    }
    let capInt;
    capInt = setInterval(timestamp, 500);
    
    formValid = false;
    $(document).ready(function () {
        $("#btnPcfaSubmit").attr("disabled", "disabled");
        $(".declaration input[type='checkbox']").prop('required', true);
        $(".declaration input[type='checkbox']").get(0).setCustomValidity("At least 1 of the criteria must be selected");
        $(".declaration input[type='checkbox']").change(function () {

            if ($(".declaration input[type='checkbox']:checked").length > 0) {
                $(".declaration input[type='checkbox']").prop('required', false);

                $(".declaration input[type='checkbox']").get(0).setCustomValidity('');
                $(".declaration input[type='checkbox']").each(function () {
                    var item = $(this);
                    validCheck(item);
                })

            } else {
                $(".declaration input[type='checkbox']").prop('required', true);
                $(".declaration input[type='checkbox']").get(0).setCustomValidity("At lease 1 of the criteria must be selected");
            }


        });
        $("p.error").insertAfter('ul.inputs>li>label,  ul.checkboxes>li>label');
        $("p.error").hide();
        $(".checkboxes.declaration p.error").remove();

        $("input").blur(function () {
            var item = $(this);

            validCheck(item);

        });
        $("ul.inputs textarea").on('blur', function () {
            var item = $(this);

            
                validCheck(item);
            
        });
        $('input[type="submit"]').click(function () {

        })
        $("#btnPcfaSubmit").click(function () {
            formValid = true;
            $('ul.inputs li input,ul.inputs textarea, ul.inputs select, ul.checkboxes input').each(function () {
                var item = $(this);
                validCheck(item);
            });
            
            //validCheck(item);
           
            if (formValid) {
                $("#btnPcfaSubmit").attr('disabled', 'disabled');
                if ($('.pcfa-submit-notification').length == 0) {
                    $("#btnPcfaSubmit").after("<p class='pcfa-submit-notification'>Please wait while we send the data to OMA.</p>")
                } else {
                    $('.pcfa-submit-notification').text('Please wait while we send the data to OMA.')
                }

                var json = JSON.stringify($("#pcfaForm").serializeArray().reduce(function (json, { name, value }) {
                    json[name] = value;
                    return json;
                }));

                //PCFA API call
                $.ajax({
                    url: "/sfApi/Salesforce/PostPCFACase/",
                    type: "POST",
                    processData: false,
                    headers: { "accept": "application/json;odata=verbose", "content-type": "application/json" },
                    data: json,

                    success: function (result) {
                        if (result.success) {
                            let heading = $(".oma-text-block-title")[0].nodeName;
                            heading = Number(heading.substr(1, 1));
                            heading++;
                            let newHead = "H" + heading.toString();

                            $("#pcfaForm").hide().after("<div class='success'><" + newHead + " class='title'>Your PCFA form has been submitted. Check the email you have on file.</" + newHead + "><p>You should receive an email within 24 hours with confirmation that we have received the form.If you do not receive a confirmation, please email <a href='mailto:pcfa@oma.org'>pcfa@oma.org</a>.</div>");
                            clearInterval(capInt);
                        }
                    },
                    error: function (error) {
                        $("#btnPcfaSubmit").removeAttr("disabled");

                        $(".pcfa-submit-notification").text("There was an error when submitting your form. Please try again later or email pcfa@oma.org;");
                        console.log({ "SFError": error.responseJSON });

                    }
                });
            } else {
                if ($(".pcfa-submit-notification").length == 0) {
                    $("#btnPcfaSubmit").after("<p class='pcfa-submit-notification'>Unable to submit if the form is incomplete. Please review for error messages.</p>")
                } else {
                    $(".pcfa-submit-notification").text("Unable to submit if the form is incomplete. Please review for error messages.");
                }
            }
            return false;
        });
    });
}
