document.addEventListener('DOMContentLoaded', () => {
    const SORTABLE_TABLE_CLASSNAME = 'jsOMASortableTable',
        SCROLLABLE_TABLE_HEADERS_CLASSNAME = 'jsOMAScrollableTableHeaders',
        SCROLLABLE_TABLE_WRAPPER_CLASSNAME = 'oma-table_scroll-right',
        SORTABLE_TABLE_HEADERS_CLASSNAME = 'jsOMASortableTableHeaders';

    const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent,

        headersTables = document.querySelectorAll(`.${SCROLLABLE_TABLE_HEADERS_CLASSNAME}`),

        comparer = (idx, asc) => (a, b) => ((v1, v2) =>
                v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ?
                    v1 - v2 : v1.toString().localeCompare(v2)
        )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

    document.querySelectorAll(`.${SORTABLE_TABLE_CLASSNAME} th`).forEach(th => th.addEventListener('click', (() => {
        document.querySelectorAll(`.${SORTABLE_TABLE_CLASSNAME} th`).forEach(el => {
            if (el !== th) el.classList.remove('sort', 'sort-up');
        });

        th.classList.add('sort');
        th.classList.toggle('sort-up');
        const table = th.closest('table');
        const tbody = table.querySelector('tbody');
        Array.from(tbody.querySelectorAll('tr'))
            .sort(comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc))
            .forEach(tr => tbody.appendChild(tr));

        const rowHeadersTable = th.closest(`.${SORTABLE_TABLE_CLASSNAME}`),
            sortedRowHeaders = rowHeadersTable.querySelectorAll('tbody tr th'),
            visibleRowHeaders = th.closest(`.${SCROLLABLE_TABLE_WRAPPER_CLASSNAME}`) ?
                th.closest(`.${SCROLLABLE_TABLE_WRAPPER_CLASSNAME}`)
                    .previousElementSibling.querySelectorAll('tbody tr th') : [];

        visibleRowHeaders.forEach((el, i) => {
            const cloned = sortedRowHeaders[i].cloneNode(true);
            cloned.classList.remove('hide-col');
            el.replaceWith(cloned);
        });

        adjustHeadersHeight(headersTables);

    })));


    document.querySelectorAll(`.${SORTABLE_TABLE_HEADERS_CLASSNAME} th`).forEach(th => th.addEventListener('click', ((e) => {
        const sortBy = th.closest(`.${SORTABLE_TABLE_HEADERS_CLASSNAME}`).nextElementSibling.querySelector('th');
        sortBy.click();
        e.target.classList.add('sort');
        sortBy.click();
        e.target.classList.toggle('sort-up');
    })));

    adjustHeadersHeight(headersTables);

    function adjustHeadersHeight(headersTables) {
        headersTables.forEach((headerTable) => {

            const headers = headerTable.querySelectorAll('tbody th'),
                topHeader = headerTable.querySelector('thead th'),
                rows = headerTable.nextElementSibling,
                thHeight = rows.querySelector('th').offsetHeight;

            rows.querySelectorAll('tbody tr').forEach((td, i) => {
                if (headers[i] != undefined) {
                    headers[i].style.height = td.offsetHeight + 'px';
                }
            });
            topHeader.style.height = thHeight + 'px';
        });
    }

    window.addEventListener('resize', () => adjustHeadersHeight(headersTables));
});

jQuery(document).ready(function () {
    jQuery(".main-table").clone(true).appendTo('#table-scroll').addClass('clone');
});
