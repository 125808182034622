import breakpoint from "./Breakpoint";

function isMobile() {
    return breakpoint() === 'xs' || breakpoint() === 'sm' || breakpoint() === 'md';
}

document.addEventListener('DOMContentLoaded', function () {

    const searchElems = Array.from(document.querySelectorAll('.search-element'));
    searchElems.forEach(elem => initSearchElem(elem));
    const body = document.querySelector("body");
    const lvl1Items = document.querySelectorAll('.jsLvl1Item');
    const overlay = document.querySelector('.jsBgOverlay');

    const navMenu = document.querySelector('.jsMenuFlyout');

    function disableScroll() {
        document.body.scrollTop = 0;
    }

    function initSearchElem(searchElem) {
        const Classes = {
            SEARCHBAR: 'jsSearchbar',
            IS_VISIBLE: 'visible',
            HIDDEN: 'hidden',
        };

        const Selectors = {
            SHOW_SEARCHBAR_BUTTON: '.jsShowSearchbarBtn',
            SEARCHBAR: '.' + Classes.SEARCHBAR,
            CLOSE_SEARCHBAR_ICON: '.jsCloseSearchbarIcon',
            PREDICTIVE_SEARCH_RESULTS_WRAPPER: '.jsPredictiveSearchResults',
            INPUT: '.jsSearchbarInput',
        };

        const showSearchbarButton = searchElem.querySelector(Selectors.SHOW_SEARCHBAR_BUTTON);
        const searchbar = searchElem.querySelector(Selectors.SEARCHBAR);
        const closeSearchbarButton = searchElem.querySelector(Selectors.CLOSE_SEARCHBAR_ICON);
        const predictiveSearchResultsWrapper = searchElem.querySelector(Selectors.PREDICTIVE_SEARCH_RESULTS_WRAPPER);
        const input = searchElem.querySelector(Selectors.INPUT);

        let delayTimer;
        let predictiveSearchResults = [];

        bindEvents();

        function bindEvents() {
            searchbar.addEventListener('keydown', (event) => {
                if (event.keyCode == 13) {
                    event.preventDefault();
                }
            })
            input.addEventListener('keyup', (event) => {
                if (event.keyCode == 13) {
                    const isAtMemberPage = window.location.pathname.split("/").includes("member");
                    if (isAtMemberPage) {
                        window.location.href = "/member/search?q=" + input.value;
                    } else {
                        window.location.href = "/search?q=" + input.value;
                    }
                }
                //loadSearchPredictiveSearchResults()
            });
            [showSearchbarButton, closeSearchbarButton].forEach(btnElem => {
                btnElem.addEventListener('click', (event) => {
                    toggleSearchBarVisibility();
                    event.stopPropagation();
                });
                btnElem.addEventListener('focus', (event) => {
                    $(lvl1Items).removeClass("active");
                    if (!isMobile()) {
                        body.removeEventListener('scroll', disableScroll);
                        overlay.classList.remove('isVisible');
                    }
                });
            });    
        }

        function toggleSearchBarVisibility() {
            showSearchbarButton.classList.toggle(Classes.IS_VISIBLE);
            searchbar.classList.toggle(Classes.IS_VISIBLE);
            closeSearchbarButton.classList.toggle(Classes.IS_VISIBLE);
            navMenu.classList.toggle(Classes.HIDDEN);

            if (searchBarIsVisible()) {
                input.focus();
                document.addEventListener('click', checkForCloseSearchBar);
                document.addEventListener('keyup', onKeyupAction);
            } else {
                document.removeEventListener('click', checkForCloseSearchBar);
                document.removeEventListener('keyup', onKeyupAction);
            }
        }

        function checkForCloseSearchBar(event) {
            if (searchBarIsVisible() && !event.target.classList.contains(Classes.SEARCHBAR) && !hasSomeParentTheClass(event.target, Classes.SEARCHBAR)) {
                toggleSearchBarVisibility();
            }
        }

        function onKeyupAction(event) {
            if (searchBarIsVisible() && event.keyCode == 27) {
                toggleSearchBarVisibility();
            }
        }

        function hasSomeParentTheClass(element, classname) {
            if (element && element.classList && element.classList.contains(classname)) {
                return true;
            }
            return element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
        }

        function searchBarIsVisible() {
            return searchbar && searchbar.classList.contains(Classes.IS_VISIBLE);
        }

        function loadSearchPredictiveSearchResults() {
            const text = input.value;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(function () {
                predictiveSearchResults = ["OMA Council Landing Page", "Council Archives", "Fall Meeting of Council - Modernizing Council - November 2019"];
                if (predictiveSearchResultsWrapper && predictiveSearchResults.length > 0) {
                    predictiveSearchResultsWrapper.innerHTML = '';
                    predictiveSearchResults.forEach(resultItem => {
                        let resultItemLink = document.createElement('a');
                        resultItemLink.href = '#';
                        resultItemLink.classList.add('predictive-search-result-item');
                        resultItemLink.textContent = resultItem;
                        predictiveSearchResultsWrapper.appendChild(resultItemLink);
                    });
                }
            }, 1000);
        }
    }
});