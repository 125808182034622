(function () {
    var recentTopicsUrl = '/communitiesApi/CommunityItemsApi/GetExchangeRecentTopicsInfo';
    var execListUrl = '/communitiesApi/CommunityItemsApi/GetExchangeExecsInfo';
    var tabBodies = $('.oma-community-tab-body');

    for (var tb of tabBodies) {
        tb = $(tb);
        var omaid = tb.find('input[name=omaid]').val();
        var containerType = tb.find('input[name=containerType]').val();
        if (omaid && containerType)
            (function(container) {
                getData(`${recentTopicsUrl}?omaId=${omaid}&containerType=${containerType}`)
                    .then(x => renderRecentTopics(x, container))
                .fail(err => console.error('recent topics failed', err));
            })(tb);
        (function (container) {
            getData(`${execListUrl}?omaId=${omaid}&containerType=${containerType}`)
                .then(x => renderExecList(x, container))
                .fail(err => console.error('exec list failed', err));
        })(tb);

    }
   

    function getData(url) {
        return $.ajax({
            url: url,
            method: 'GET',
            dataType: 'json',
            contentType: 'application/json'
        });
    }

    function renderRecentTopics(data, tabContainer) {
       
        var url = '/OMACommunityItems/GetRecentTopicsView';
        getRenderedView(url, data).then(view => {
         
            var container = tabContainer.find('.right-section-block.recent-topics');
            
            container = $(container);
            
            container.html('');
            container.html(view);
        });
    }
    function renderExecList(data, tabContainer) {
        
        var url = '/OMACommunityItems/GetExecutiveListView';
        getRenderedView(url, data).then(view => {
           
            var container = tabContainer.find('.right-section-block.exec-list');
           
            container = $(container);
            container.html('');
            container.html(view);
        });
    }
    function getRenderedView(url, data) {
        return $.ajax({
            url: url.toLowerCase(),
            method: 'POST',
            dataType: 'html',
            contentType: 'application/json',
            data: JSON.stringify(data)
        });
    }
})();