import breakpoint from "./Breakpoint";

function isMobile() {
    return breakpoint() === 'xs' || breakpoint() === 'sm' || breakpoint() === 'md';
}

function isLargeScreen() {
    return breakpoint() === 'lg';
}

document.addEventListener('DOMContentLoaded', () => {

    const
        MENU_CLASSNAME = 'jsNavigationMenu',
        MENU_FLYOUT_CLASSNAME = 'jsMenuFlyout',
        MENU_MOBILE_TOGGLE_CLASSNAME = 'jsMobileMenuToggle',
        IS_VISIBLE_CLASSNAME = 'isVisible',
        WITH_ALL_TRANSITION_CLASSNAME = 'with-all-transition',
        ACTIVE_CLASSNAME = 'active',
        EXPANDED_CLASSNAME = 'expanded',
        CHOSEN_CLASSNAME = 'chosen',
        LVL1_ITEM = 'jsLvl1Item',
        LVL1_ITEM_EXPAND_BTN = 'jsLvl1ItemExpandIcon',
        LVL2_ITEM = 'jsLvl2Item',
        LVL2_ITEM_EXPAND_BTN = 'jsLvl2ItemExpandIcon',
        LVL3_ITEM = 'jsLvl3Item',
        LVL3_ITEM_EXPAND_BTN = 'jsLvl3ItemExpandIcon',
        LVL4_ITEM = 'jsLvl4Item',
        LVL4_ITEM_EXPAND_BTN = 'jsLvl4ItemExpandIcon',
        LVL5_CONTENT_CLASSNAME = 'jsLvl5Content',
        LVL5_ITEM = 'jsLvl5Item',
        LVL5_ITEM_EXPAND_BTN = 'jsLvl5ItemExpandIcon',
        LVL6_CONTENT_CLASSNAME = 'jsLvl6Content',
        LVL6_ITEM = 'jsLvl6Item',
        LVL2_UL_CLASSNAME = 'jsUlLvl2',
        OVERLAY_CLASSNAME = 'jsBgOverlay',
        HEADER_CLASSNAME = 'jsOmaHeader',
        MEMBER_MENU_CLASSNAME = 'oma-member-menu-item',
        USER_INFO_ITEM = 'user-information-item',
        LVL1_LINK_WRAPPER = 'jsLvl1LinkWrapper',
        LVL1_LINK = 'jsLvl1Link',
        BACKDROP_PANEL = 'jsDynamicBackdropPanel',
        COLUMNS_CONTAINER = 'jsColumnsContainer';

    const body = document.querySelector("body");
    let overlayDiv = document.createElement("div");
    overlayDiv.classList.add('bg-overlay', 'jsBgOverlay');
    body.insertBefore(overlayDiv, body.firstChild);

    const header = document.querySelector(`.${HEADER_CLASSNAME}`);
    const votingReminder = document.querySelector('.oma-notification-wrapper');

    const menu = document.querySelector(`.${MENU_CLASSNAME}`);
    if (!menu) return;

    const menuMobileToggle = menu.querySelector(`.${MENU_MOBILE_TOGGLE_CLASSNAME}`);
    const menuFlyOut = menu.querySelector(`.${MENU_FLYOUT_CLASSNAME}`);
    const lvl1Items = menu.querySelectorAll(`.${LVL1_ITEM}`);
    const lvl2Items = menu.querySelectorAll(`.${LVL2_ITEM}`);
    const lvl3Items = menu.querySelectorAll(`.${LVL3_ITEM}`);
    const lvl4Items = menu.querySelectorAll(`.${LVL4_ITEM}`);
    const lvl5Items = menu.querySelectorAll(`.${LVL5_ITEM}`);
 
    const overlay = document.querySelector(`.${OVERLAY_CLASSNAME}`);
    const lvl5Content = menu.querySelectorAll(`.${LVL5_CONTENT_CLASSNAME}`);
    const lvl6Content = menu.querySelectorAll(`.${LVL6_CONTENT_CLASSNAME}`);
    const memberMenuItems = document.querySelectorAll(`.${MEMBER_MENU_CLASSNAME}`);
    const userInfoItem = document.querySelector(`.${USER_INFO_ITEM}`);

    const lvl1LinkWrappers = document.querySelectorAll(`.${LVL1_LINK_WRAPPER}`);
    const backdropPanel = document.querySelector(`.${BACKDROP_PANEL}`);

    const resetMenuState = () => {
        lvl5Content.forEach(content => content.classList.remove(IS_VISIBLE_CLASSNAME));
    };

    function disableScroll() {
        document.body.scrollTop = 0;
    }

    // adjust z-indexes
    const lvl2Flyouts = menu.querySelectorAll(`.${LVL2_UL_CLASSNAME}`);
    lvl2Flyouts.forEach((ul, i) => {
        const minZindex = 10;
        ul.style.zIndex = (lvl2Flyouts.length + minZindex - i).toString();
    });

    menuMobileToggle.addEventListener('click', function (e) {
        resetMenuState();
        menuFlyOut.classList.toggle(IS_VISIBLE_CLASSNAME);
        const top = header.clientHeight + (votingReminder ? votingReminder.clientHeight : 0);
        overlay.style.top = `${top + 2}px`;
        overlay.style.height = `calc(100% - ${top + 2}px)`;
        overlay.classList.toggle(IS_VISIBLE_CLASSNAME);
        menu.classList.toggle(IS_VISIBLE_CLASSNAME);
        menuMobileToggle.querySelector('i').classList.toggle(ACTIVE_CLASSNAME);
        e.preventDefault();
    });

    if (isLargeScreen()) {
        lvl1LinkWrappers.forEach((lvl1LinkWrapper) => {
            const link = lvl1LinkWrapper.querySelector(`.${LVL1_LINK}`);
            const wrapperStyles = window.getComputedStyle(lvl1LinkWrapper);

            const clonedLink = link.cloneNode(false);
            const clonedLinkWrapper = document.createElement('div');

            clonedLinkWrapper.style.paddingLeft = wrapperStyles.paddingLeft;
            clonedLinkWrapper.style.paddingRight = wrapperStyles.paddingRight;
            clonedLinkWrapper.style.display = 'inline-block';
            clonedLinkWrapper.style.visibility = 'hidden';
            clonedLinkWrapper.style.float = 'left';

            lvl1LinkWrapper.parentNode.insertBefore(clonedLinkWrapper, lvl1LinkWrapper.nextSibling);
            clonedLinkWrapper.appendChild(clonedLink);

            window.addEventListener('resize', function () {
                lvl1LinkWrapper.style.width = clonedLinkWrapper.style.width + 1;
                clonedLinkWrapper.style.marginTop = lvl1LinkWrapper.style.height - wrapperStyles.paddingTop - wrapperStyles.paddingBottom;
            });
        });
    }

    lvl1Items.forEach((lvl1Item) => {
        function showBackDrop() {
            if (backdropPanel) {
                const columnsContainer = lvl1Item.querySelector(`.${COLUMNS_CONTAINER}`);
                backdropPanel.style.height = `${columnsContainer.clientHeight + 20}px`;
                backdropPanel.style.opacity = 1;
                setTimeout(() => backdropPanel.classList.add(WITH_ALL_TRANSITION_CLASSNAME));
            }
        }
        function hideBackDrop() {
            if (backdropPanel) {

                backdropPanel.style.opacity = 0;
                backdropPanel.style.height = 0;
            }
            lvl1Item.classList.remove('active');
        }
        const lvl1ItemExpandBtn = lvl1Item.querySelector(`.${LVL1_ITEM_EXPAND_BTN}`);
        if (lvl1ItemExpandBtn) {
            lvl1ItemExpandBtn.addEventListener('click', function (e) {
                e.preventDefault();
                menu.querySelectorAll('li').forEach(ul => ul.classList.remove(CHOSEN_CLASSNAME));
                if (e.target.parentNode.parentNode === lvl1Item) {
                    lvl1Item.querySelectorAll('li').forEach(ul => ul.classList.remove(EXPANDED_CLASSNAME));
                    lvl1Item.classList.toggle(EXPANDED_CLASSNAME);
                    lvl1Item.classList.toggle(CHOSEN_CLASSNAME);
                }
            });
        }

        lvl1Item.addEventListener('mouseover', function (e) {
            let subItems = lvl1Item.querySelectorAll(`.${LVL2_ITEM}`);
            if (!isMobile() && subItems != null && subItems.length > 0) {
                const top = header.clientHeight + (votingReminder ? votingReminder.clientHeight : 0);
                overlay.style.top = `${top}px`;
                overlay.style.height = `calc(100% - ${top}px)`;
                overlay.classList.add(IS_VISIBLE_CLASSNAME);
                showBackDrop();
            }
        });

        lvl1Item.addEventListener('mouseout', function (e) {
            if (!isMobile()) {
                overlay.classList.remove(IS_VISIBLE_CLASSNAME);
                hideBackDrop();
            }
        });

        lvl1Item.firstElementChild.firstElementChild.addEventListener('focus', function (e) {
            let subItems = lvl1Item.querySelectorAll(`.${LVL2_ITEM}`);
            $(lvl1Items).removeClass("active");
            $(lvl1Item).addClass("active");
            if (!isMobile() && subItems != null && subItems.length > 0) {
                overlay.style.top = `${header.clientHeight}px`;
                overlay.style.height = `calc(100% - ${header.clientHeight}px)`;
                overlay.classList.add(IS_VISIBLE_CLASSNAME);
                showBackDrop();
            }
        });

        lvl1Item.addEventListener('blur', function (e) {
            if (!isMobile()) {
                overlay.classList.remove(IS_VISIBLE_CLASSNAME);
                hideBackDrop();
            }
        });
    });

    lvl2Items.forEach((lvl2Item) => {
        const lvl2ItemExpandBtn = lvl2Item.querySelector(`.${LVL2_ITEM_EXPAND_BTN}`);
        if (lvl2ItemExpandBtn) {
            lvl2ItemExpandBtn.addEventListener('click', function (e) {
                e.preventDefault();
                menu.querySelectorAll('li').forEach(ul => ul.classList.remove(CHOSEN_CLASSNAME));
                if (e.target.parentNode.parentNode === lvl2Item) {
                    lvl2Item.querySelectorAll('li').forEach(ul => ul.classList.remove(EXPANDED_CLASSNAME));
                    lvl2Item.classList.toggle(EXPANDED_CLASSNAME);
                    lvl2Item.classList.toggle(CHOSEN_CLASSNAME);
                }
            });
        }
    });

    lvl3Items.forEach((lvl3Item) => {
        const lvl3ItemExpandBtn = lvl3Item.querySelector(`.${LVL3_ITEM_EXPAND_BTN}`);
        if (lvl3ItemExpandBtn) {
            lvl3ItemExpandBtn.addEventListener('click', function (e) {
                e.preventDefault();
                menu.querySelectorAll('li').forEach(ul => ul.classList.remove(CHOSEN_CLASSNAME));
                if (e.target.parentNode.parentNode === lvl3Item) {
                    lvl3Item.querySelectorAll('li').forEach(ul => ul.classList.remove(EXPANDED_CLASSNAME));
                    lvl3Item.classList.toggle(EXPANDED_CLASSNAME);
                    lvl3Item.classList.toggle(CHOSEN_CLASSNAME);
                }
            });
        }
    });

    lvl4Items.forEach((lvl4Item) => {
        const lvl4ItemExpandBtn = lvl4Item.querySelector(`.${LVL4_ITEM_EXPAND_BTN}`);
        if (lvl4ItemExpandBtn) {
            lvl4ItemExpandBtn.addEventListener('click', function (e) {
                e.preventDefault();
                menu.querySelectorAll('li').forEach(ul => ul.classList.remove(CHOSEN_CLASSNAME));
                if (e.target.parentNode.parentNode === lvl4Item) {
                    lvl4Item.querySelectorAll('li').forEach(ul => ul.classList.remove(EXPANDED_CLASSNAME));
                    lvl4Item.classList.toggle(EXPANDED_CLASSNAME);
                    lvl4Item.classList.toggle(CHOSEN_CLASSNAME);
                }
            });
        }
    });

    lvl5Items.forEach((lvl5Item) => {
        const lvl5ItemExpandBtn = lvl5Item.querySelector(`.${LVL5_ITEM_EXPAND_BTN}`);
        if (lvl5ItemExpandBtn) {
            lvl5ItemExpandBtn.addEventListener('click', function (e) {
                e.preventDefault();
                menu.querySelectorAll('li').forEach(ul => ul.classList.remove(CHOSEN_CLASSNAME));
                if (e.target.parentNode.parentNode === lvl5Item) {
                    lvl5Item.querySelectorAll('li').forEach(ul => ul.classList.remove(EXPANDED_CLASSNAME));
                    lvl5Item.classList.toggle(EXPANDED_CLASSNAME);
                    lvl5Item.classList.toggle(CHOSEN_CLASSNAME);
                }
            });
        }
    });

    lvl6Content.forEach((content) => {
        content.addEventListener('mouseleave', function (e) {
            if (!isMobile()) {
                resetMenuState();
            }
        })
    });

    memberMenuItems.forEach((item) => {
        $(item).on('keyup', function (event) {
            if (event.which === 13) {
                let isActive = item.classList.contains('active');
                if (!isActive) {
                    [].forEach.call(memberMenuItems, function (el) {
                        el.classList.remove("active");
                    });
                    item.classList.add('active');
                } else {
                    item.classList.remove("active");
                }
            }
        });
    });

    memberMenuItems.forEach((item) => {
        item.addEventListener('click', function (e) {
            let isActive = item.classList.contains("active");
            const panels = document.getElementsByClassName('panel');
            if (!isActive) {
                [].forEach.call(memberMenuItems, function (el) {
                    el.classList.remove("active");
                });
                item.classList.add('active');
            } else if (isActive && !(e.composedPath().includes(panels[0]) || e.composedPath().includes(panels[1]))) {
                item.classList.remove("active");
            }
        });
    });

    body.addEventListener('click', function (e) {
        const menu = document.getElementById('menu');
        const memberMenu = document.getElementById('member-menu');

        if (e.composedPath().indexOf(menu) < 0) {
            $(lvl1Items).removeClass("active");
            if (!isMobile()) {
                overlay.classList.remove(IS_VISIBLE_CLASSNAME);
            }
        }

        if (e.composedPath().indexOf(memberMenu) < 0) {
            [].forEach.call(memberMenuItems, function (el) {
                el.classList.remove("active");
            });
        }
    });

    if (userInfoItem) {
        userInfoItem.addEventListener('focus', function (e) {
            $(lvl1Items).removeClass("active");
            if (!isMobile()) {
                body.removeEventListener('scroll', disableScroll);
                overlay.classList.remove(IS_VISIBLE_CLASSNAME);
            }
        });
    }

    if ($(`.${LVL6_ITEM}.active`).length > 0) {
        const lvl6Item = $(`.${LVL6_ITEM}.active`)[0];
        const lvl5Item = $(lvl6Item.closest(`.${LVL5_ITEM}`))[0];
        const lvl4Item = $(lvl5Item.closest(`.${LVL4_ITEM}`))[0];
        const lvl3Item = $(lvl4Item.closest(`.${LVL3_ITEM}`))[0];
        const lvl2Item = $(lvl3Item.closest(`.${LVL2_ITEM}`))[0];
        const lvl1Item = $(lvl2Item.closest(`.${LVL1_ITEM}`))[0];

        $(lvl5Item).addClass('expanded');
        $(lvl4Item).addClass('expanded');
        $(lvl3Item).addClass('expanded');
        $(lvl2Item).addClass('expanded');
        $(lvl1Item).removeClass('chosen');
    }

    if ($(`.oma-navigation-menu__link-wrapper._lvl5.active`).length > 0) {
        const lvl5Item = $('.oma-navigation-menu__link-wrapper._lvl5.active')[0];
        const lvl4Item = $(lvl5Item.closest(`.${LVL4_ITEM}`))[0];
        const lvl3Item = $(lvl4Item.closest(`.${LVL3_ITEM}`))[0];
        const lvl2Item = $(lvl3Item.closest(`.${LVL2_ITEM}`))[0];
        const lvl1Item = $(lvl2Item.closest(`.${LVL1_ITEM}`))[0];

        $(lvl4Item).addClass('expanded');
        $(lvl3Item).addClass('expanded');
        $(lvl2Item).addClass('expanded');
        $(lvl1Item).removeClass('chosen');
    }

    if ($(`.oma-navigation-menu__link-wrapper._lvl4.active`).length > 0) {
        const lvl4Item = $('.oma-navigation-menu__link-wrapper._lvl4.active')[0];
        const lvl3Item = $(lvl4Item.closest(`.${LVL3_ITEM}`))[0];
        const lvl2Item = $(lvl3Item.closest(`.${LVL2_ITEM}`))[0];
        const lvl1Item = $(lvl2Item.closest(`.${LVL1_ITEM}`))[0];

        $(lvl3Item).addClass('expanded');
        $(lvl2Item).addClass('expanded');
        $(lvl1Item).removeClass('chosen');
    }

    if ($('.oma-navigation-menu__link-wrapper._lvl3.active').length > 0) {
        const lvl3Item = $('.oma-navigation-menu__link-wrapper._lvl3.active')[0];
        const lvl2Item = $(lvl3Item.closest(`.${LVL2_ITEM}`))[0];
        const lvl1Item = $(lvl2Item.closest(`.${LVL1_ITEM}`))[0];

        $(lvl2Item).addClass('expanded');
        $(lvl1Item).removeClass('chosen');
    }

    if ($('.oma-navigation-menu__link-wrapper._lvl2.active').length > 0) {
        const lvl2Item = $('.oma-navigation-menu__link-wrapper._lvl2.active')[0];
        const lvl1Item = $(lvl2Item.closest(`.${LVL1_ITEM}`))[0];

        $(lvl1Item).removeClass('chosen');
    }
});