import 'bootstrap';
import '../../scss/oma/index.scss';
import "./ExpandingContent"; 
import "./Tables.js";
import "./NavigationMenu.js";
import './pages/oma-news-events-list-page.js';
import './pages/oma-speaker-search-page.js';
import './pages/oma-communities-page';
import './priority-cta';
import './blocks/oma-profile-block.js';
import './blocks/left-nav-block.js';
import './pages/oma-discounts.js';
import './pages/oma-marketplace-advanced-search-page.js';
import './oma-header-search-bar.js';
import './blocks/oma-emarketplace-company-block.js';
import './blocks/video-collection-block.js';
import './table-custom-filter-search.js';
import './pages/oma-search-result-page.js';
import './blocks/oma-notification.js';
import './blocks/oma-candidate-block.js';
import './pages/oma-community-items.js';
import './pages/oma-communities-exchange-feed';
import './blocks/oma-apply-cta';
import './blocks/omai-help-me-choose';
import './blocks/iframe.js';
import './blocks/masonry-container.js';
import './rio';
import './blocks/oma-salesforce-case-form-block.js';
import './pcfa';
import './paf';
window.$ = window.jQuery = $;
//console.log('jquery ver', jQuery.fn.jquery);
